import { darkPalette, lightPalette } from './colorPalette';
import { ThemeTypes } from '../../Context/Theme/ThemeContext';

declare module 'styled-components' {
  export interface DefaultTheme {
    primary: string;
    secondary: string;
    text: string;
    detailsText: string;
    alternateText: string;
    redBackground: string;
    sideMenu: string;
    sideMenuAccent: string;
    priceText: string;
    title: string;
    accent: string;
    hover: string;
    arrowIcon: string;
    arrowIconHovered: string;
    settingsBorderColor: string;
    settingsBorderOutlineColor: string;
    filter: string;
    primaryFilter: string;
    activeFilter: string;
    accentFilter: string;
    transition: string;
    shadowColor: string;
    borderColor: string;
  }
}

export const lightTheme = {
  primary: lightPalette.primary,
  secondary: lightPalette.secondary,
  text: lightPalette.text,
  detailsText: lightPalette.detailsText,
  alternateText: lightPalette.alternateText,
  redBackground: lightPalette.redBackground,
  sideMenu: lightPalette.sidemenu,
  sideMenuAccent: lightPalette.sideMenuAccent,
  priceText: lightPalette.priceText,
  title: lightPalette.title,
  accent: lightPalette.accent,
  hover: lightPalette.hover,
  arrowIcon: lightPalette.arrowIcon,
  arrowIconHovered: lightPalette.arrowIconHovered,
  settingsBorderColor: lightPalette.settingsBorderColor,
  settingsBorderOutlineColor: lightPalette.settingsBorderOutlineColor,
  filter: lightPalette.filter,
  primaryFilter: lightPalette.primaryFilter,
  activeFilter: lightPalette.activeFilter,
  accentFilter: lightPalette.accentFilter,
  transition: lightPalette.transition,
  shadowColor: lightPalette.shadowColor,
  borderColor: lightPalette.borderColor,
};

export const darkTheme = {
  primary: darkPalette.primary,
  secondary: darkPalette.secondary,
  text: darkPalette.text,
  detailsText: darkPalette.detailsText,
  alternateText: darkPalette.alternateText,
  redBackground: darkPalette.redBackground,
  sideMenu: darkPalette.sidemenu,
  sideMenuAccent: darkPalette.sideMenuAccent,
  priceText: darkPalette.priceText,
  title: darkPalette.title,
  accent: darkPalette.accent,
  hover: darkPalette.hover,
  arrowIcon: darkPalette.arrowIcon,
  arrowIconHovered: darkPalette.arrowIconHovered,
  settingsBorderColor: darkPalette.settingsBorderColor,
  settingsBorderOutlineColor: darkPalette.settingsBorderOutlineColor,
  filter: darkPalette.filter,
  primaryFilter: darkPalette.primaryFilter,
  activeFilter: darkPalette.activeFilter,
  accentFilter: darkPalette.accentFilter,
  transition: darkPalette.transition,
  shadowColor: darkPalette.shadowColor,
  borderColor: darkPalette.borderColor,
};

export const setupThemeProvider = (value: string) => {
  if (value === ThemeTypes.LIGHT_THEME) {
    return lightTheme;
  } else {
    return darkTheme;
  }
};
