import { useState } from 'react';

import {
  AddonContainer,
  ArrowIconContainer,
  CenterContainer,
  ComingSoon,
  Container,
  LeftArrow,
  LeftBarParagraph,
  RightArrow,
  SettingsContainer,
} from './LeftbarLayout.styled';
import { SettingsDropdown } from '../../Components/Dropdown/SettingsDropdown';

interface LeftbarLayoutProps {
  isLeftbarOpen: boolean;
  isLeftbarTransitioning: boolean;
  toggleLeftbar: () => void;
}

const LeftbarLayout: React.FC<LeftbarLayoutProps> = ({
  isLeftbarOpen,
  isLeftbarTransitioning,
  toggleLeftbar,
}) => {
  const [addonsEnabled] = useState<boolean>(false);

  return (
    <Container isLeftbarOpen={isLeftbarOpen}>
      <CenterContainer>
        <ArrowIconContainer onClick={toggleLeftbar}>
          {isLeftbarOpen ? (
            <LeftArrow sx={{ fontSize: '50px' }} />
          ) : (
            <RightArrow sx={{ fontSize: '50px' }} />
          )}
        </ArrowIconContainer>

        <AddonContainer
          to={'/'}
          enabled={addonsEnabled}
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <LeftBarParagraph bold={true}>
            Options <ComingSoon>(coming soon)</ComingSoon>
          </LeftBarParagraph>
        </AddonContainer>
        <AddonContainer
          to={'/'}
          enabled={addonsEnabled}
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <LeftBarParagraph>AI Route Planner</LeftBarParagraph>
        </AddonContainer>
        <AddonContainer
          to={'/'}
          enabled={addonsEnabled}
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <LeftBarParagraph>Load Scheduler</LeftBarParagraph>
        </AddonContainer>
        <AddonContainer
          to={'/'}
          enabled={addonsEnabled}
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <LeftBarParagraph>Chat History</LeftBarParagraph>
        </AddonContainer>
        <AddonContainer
          to={'/'}
          enabled={addonsEnabled}
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <LeftBarParagraph>Analytics</LeftBarParagraph>
        </AddonContainer>
        <SettingsContainer
          isLeftbarOpen={isLeftbarOpen}
          isLeftbarTransitioning={isLeftbarTransitioning}
        >
          <SettingsDropdown />
        </SettingsContainer>
      </CenterContainer>
    </Container>
  );
};

export default LeftbarLayout;
