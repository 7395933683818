import useAxios from "../useAxios/useAxios";
import {LOGIN_ENDPOINT, RESET_REQUEST_ENDPOINT, USER_INFO_ENDPOINT} from "../../App/Axios/endpoints";
import {AxiosResponse} from "axios";
import {IError, IResponse} from "../../Models/BaseModel";

const useAuthenticationApi = () => {
    // axios hook
    const axios = useAxios();
    const authAxios = useAxios(true);

    // login to Django server
    const loginApi = async (email: string, password: string) => {
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            const response = await axios.post(LOGIN_ENDPOINT, formData);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    const getUser = async () => {
        try {
            const response = await authAxios.get(USER_INFO_ENDPOINT);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            return null;
        } catch (e) {
            return null;
        }
    };



    return { loginApi, getUser };
}


export default useAuthenticationApi;
