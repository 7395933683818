import useAxios from "../useAxios/useAxios";
import {
    LOGIN_123LB_ENDPOINT,
    LOGIN_TRUCKSTOP_ENDPOINT,
    SEND_MESSAGE_ENDPOINT,
    CHECK_TRUCKSTOP_ENDPOINT,
    TRUCKSTOP_RATINGS_ENDPOINT
} from "../../App/Axios/endpoints";

const useChatApi = () => {
    const authAxios = useAxios(true);

    const truckStopRating = async (code: string) => {
        // console.log(TRUCKSTOP_RATINGS)
        try {
            const url = `${TRUCKSTOP_RATINGS_ENDPOINT}?code=${code}`;
            const response = await authAxios.post(url)
            if (response.status === 200 || response.status === 201) {
                console.log(response);
                return response.data;
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    };
    
    const login123LB = async (code: string) => {
        try {
            const url = `${LOGIN_123LB_ENDPOINT}?code=${code}`;
            const response = await authAxios.get(url);
            if (response.status === 200 || response.status === 201) {
                console.log(response);
                return response.data;
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    const loginTruckstop = async (integrationId: string) => {
        try {
            const url = `${LOGIN_TRUCKSTOP_ENDPOINT}?ts_id=${integrationId}`
            const response = await authAxios.post(url);
            if (response.status === 200 || response.status === 201) {
                console.log(response)
                return response.data;
            }
            return null;
        } catch (e) {
            console.log(e)
            return null;
        }

    }

    const checkTruckstopIntegration = async (): Promise<boolean> => {
        try {
            const url = `${CHECK_TRUCKSTOP_ENDPOINT}`
            const response = await authAxios.post(url);
            if (response.status === 200 || response.status === 201) {
                console.log(response)
                return response.data.data;
            }
            return false;
        } catch (e) {
            console.log(e)
            return false;
        }

    }

    const sendMessage = async (text: string, boards: string[]) => {
        try {
            const url = `${SEND_MESSAGE_ENDPOINT}`
            const response = await authAxios.post(url, {text: text, boards: boards});
            return response;
        } catch (e: any) {
            console.log(e);
            return e.response
        }
    };
  
    return {
        login123LB,
        loginTruckstop,
        sendMessage,
        checkTruckstopIntegration, 
        truckStopRating
    };
}

export default useChatApi;
