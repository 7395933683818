import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

import { sizes } from '../../Utils/screenUtil';
import { CustomSettingsModal } from './CustomSettingsModal';

export const SettingsModalWrapper = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen((prevIsOpenState) => !prevIsOpenState);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevents click event from bubbling to the parent
  };

  const matches2240px = useMediaQuery('(min-width:2240px)');

  return (
    <SettingsFullContainer onClick={handleClick}>
      <StyledSettingsIcon sx={{ fontSize: matches2240px ? '30px' : '20px' }} />
      <span>Settings</span>
      <CustomSettingsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleModalClick={handleModalClick}
      />
    </SettingsFullContainer>
  );
};

const SettingsFullContainer = styled.div`
  position: absolute;
  bottom: 30px;
  color: ${(props) => props.theme.detailsText};
  font-size: 1rem;
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  :hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-weight: 600 !important;
    font-size: 1.6rem !important;
  }
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${(props) => props.theme.detailsText};
  margin-right: 5px;
  transform: translateY(2px);
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240 pixels */
    transform: translateY(5px);
  }
  @media only screen and (max-width: 1320px) {
    /* Styles for screens less than 1320 pixels */
    transform: translateY(4px);
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425 pixels */
    transform: translateY(5px);
  }
`;
