import React, { useContext, useState, MouseEvent } from 'react';
import { FlexColumn } from '../../App/Styles/Container.styled';
import questionSvg from '../../Assets/question.svg';
import loadboardPng from '../../Assets/123loadboard.png';
import truckStopIco from '../../Assets/truckStop.png';
import FMCSA from '../../Assets/FMCSA.png';
import { VectorImage } from '../../App/Styles/Image.styled';
import { PluginContext } from '../../Context/Plugin/PluginContext';
import { Popup } from '../../Components/Popup/Popup';
import useChatApi from '../../Hooks/useApi/useChatApi';
import {
  AddPluginButton,
  AddPluginButtonAlternate,
  CenterContainer,
  ColumnRightTitle,
  Container,
  FlexColumnProviderContainer,
  FlexColumnRightLayout,
  FlexRowProvider,
  NonClickablePluginContainer,
  OfflineLamp,
  OnlineLamp,
  ParagraphBold20,
  ParagraphWithIcon,
  PluginContainerButton,
  PluginContainerRightbar,
  ResponsiveImage,
  ResponsiveParagraph,
  ResponsiveText,
  ThinLine,
} from './RightbarLayout.styled';

const get123LoadboardLoginURL = () => {
  const data = {
    client_id: process.env.REACT_APP_123LB_CLIENT_ID!,
    scope: 'loadsearching ratecheck',
    resource: '0',
    response_type: 'code',
    state: '0',
    redirect_uri: process.env.REACT_APP_123LB_REDIRECT_URL!,
  };
  const params = new URLSearchParams(data).toString();
  // TODO replace with env variable
  return `https://api.dev.123loadboard.com/authorize?${params}`;
};
const getTruckstopRateInsightsLoginURL = () => {
  const data = {
    client_id: '7442E623-3941-47D8-9F3F-1DABF1F2923E',
    scope: 'truckstop',
    response_type: 'code',
    state: 'abcd',
    redirect_uri:"https://api.truckstop.com/auth/processcode"
  };
  const params = new URLSearchParams(data).toString();
  return `https://api.truckstop.com/auth/authorize?${params}`
};

const RightbarLayout = () => {
  const pluginContext = useContext(PluginContext);
  const { loginTruckstop, truckStopRating } = useChatApi();
  const [enablePopup, setEnablePopup] = useState(false);

  const handleTruckstopSubmit = async (data: string) => {
    // Send Integration ID
    const res = await loginTruckstop(data);

    console.log(res);
    // Check if valid response

    if (res !== null && res !== undefined) {
      if (res.success) pluginContext!.setTruckstopPlugin(true);
    }
  };

  // Turn truckstop on and off
  const toggleTruckstop = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    pluginContext!.setTruckstopPlugin(!pluginContext!.truckstopPlugin);
  };
  const testTruckStopRating = async () => {
    const testCode = 'bc66f451b42340e6aff9ca404a6a198362205160ac5142b7a16651c85b83aeb0'; // Replace with actual code if available
    const response = await truckStopRating("5655afbd4c2d4ad79e207fe0f5bb23b825050ad79eb74fb29cb5eb59de3e9d43"); // Calling the function
    console.log('Truckstop Rating Response:', response); // Log the response to console
  };

console.log(process.env.REACT_APP_123LB_REDIRECT_URL, )
  
  return (
    <Container>
      {enablePopup ? (
        <Popup
          title="Enter Your Integration ID"
          subtitle="For more information go to https://truckstop.com"
          handleExit={() => setEnablePopup(false)}
          handleSubmit={handleTruckstopSubmit}
          hasInput={true}
        />
      ) : (
        <></>
      )}

      <CenterContainer>
        <FlexColumnRightLayout justify="space-between" alignItems="center">
          <ColumnRightTitle bold={false}>Manage Providers</ColumnRightTitle>

          <AddPluginButton>
            <ParagraphBold20>Add Provider</ParagraphBold20>
          </AddPluginButton>

          <AddPluginButtonAlternate>
            <FlexRowProvider justify="center">
              <ParagraphWithIcon bold={false}>
                Your Providers <VectorImage src={questionSvg} />
              </ParagraphWithIcon>
            </FlexRowProvider>
          </AddPluginButtonAlternate>

          <FlexColumnProviderContainer
            justify="flex-start"
            width="100%"
            gap="1rem"
          >
            <PluginContainerButton
              onClick={() => setEnablePopup(true)}
              onContextMenu={toggleTruckstop}
              enabled={true}
            >
              <ResponsiveImage src={truckStopIco} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>Truckstop</ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.truckstopPlugin ? (
                <OnlineLamp />
              ) : (
                <OfflineLamp />
              )}
            </PluginContainerButton>
            <PluginContainerButton onClick={testTruckStopRating} enabled={true}>
              <ResponsiveImage src={truckStopIco} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>Test Truckstop Rating</ResponsiveParagraph>
                <ResponsiveText>Click to Test</ResponsiveText>
              </FlexColumn>
            </PluginContainerButton>
            <PluginContainerButton
      onClick={() => window.location.href = getTruckstopRateInsightsLoginURL()}
      enabled={true}
    >
      <ResponsiveImage src={truckStopIco} width="2rem" height="2rem" />
      <FlexColumn justify="center" gap="0.1rem" alignItems="center">
        <ResponsiveParagraph bold={true}>Truckstop Rate Insights</ResponsiveParagraph>
        <ResponsiveText>use provider</ResponsiveText>
      </FlexColumn>
    </PluginContainerButton>
            <PluginContainerRightbar
              to={get123LoadboardLoginURL()}
              enabled={true}
              hasBorder
            >
              <ResponsiveImage src={loadboardPng} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>
                  123Loadboard
                </ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.LB123Plugin ? <OnlineLamp /> : <OfflineLamp />}
            </PluginContainerRightbar>
            <ThinLine />
            <NonClickablePluginContainer>
              <ResponsiveImage src={FMCSA} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>FMCSA</ResponsiveParagraph>
                <ResponsiveText>Company Analysis</ResponsiveText>
              </FlexColumn>
              <OnlineLamp />
            </NonClickablePluginContainer>
          </FlexColumnProviderContainer>
        </FlexColumnRightLayout>
      </CenterContainer>
    </Container>
  );
};

export default RightbarLayout;
