import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useChatApi from "../../../Hooks/useApi/useChatApi";
import { PluginContext } from "../../../Context/Plugin/PluginContext";

const LoginRedirectRatingsPage = () => {
    const pluginContext = useContext(PluginContext);
    const navigate = useNavigate();
    const { loginTruckstop } = useChatApi();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);  // Get query params from the URL

    useEffect(() => {
        console.log('Processing Truckstop OAuth...');
        (async () => {
            try {
                // Retrieve the authorization code from the query string
                const code = queryParams.get('code');  
                if (!code) {
                    console.log('No authorization code found');
                    return;
                }

                // Exchange the authorization code for an access token
                const data = await loginTruckstop(code);
                if (!data) {
                    console.log('Token exchange failed');
                    return;
                }

                // Set the plugin state to indicate success
                pluginContext?.setTruckstopPlugin(true);

                // Redirect to the homepage after successful login
                navigate("/");
            } catch (error) {
                console.error('Error during Truckstop OAuth flow:', error);
                navigate("/error");  // Redirect to an error page if the process fails
            }
        })();
    }, [pluginContext, navigate, loginTruckstop, location]);

    return (
        <div>
            <h2>Processing authentication with Truckstop...</h2>
        </div>
    );
};

export default LoginRedirectRatingsPage;
