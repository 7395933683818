import styled from 'styled-components';

import { FlexColumn, FlexRow } from '../../App/Styles/Container.styled';
import { Image, VectorImage } from '../../App/Styles/Image.styled';
import { Link } from 'react-router-dom';
import { HiddenButton } from '../../App/Styles/Button.styled';
import { Paragraph, Text } from '../../App/Styles/Typography.styled';

export const Container = styled.div`
  grid-area: right;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0rem 1.5rem 1rem 0rem;
`;

export const CenterContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
  border-radius: 20px;
  max-height: 90%;
  background-color: ${(props) => props.theme.sideMenu};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border-top: 1px solid ${(props) => `${props.theme.detailsText}30`};
    border-left: 1px solid ${(props) => `${props.theme.detailsText}30`};
    border-right: 1px solid ${(props) => `${props.theme.detailsText}30`};
    border-bottom: none;
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    pointer-events: none;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    width: 450px;
    transform: translateX(-100px);
  }
`;

export const AddPluginButton = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 0.8rem;
  width: 75%;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.title};
  cursor: alias;
  opacity: 0.3;
  aspect-ratio: 4 / 1;

  ${Paragraph} {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 1024px) {
    padding: 0.2rem;
    ${Paragraph} {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    padding: 0.1rem;
    ${Paragraph} {
      font-size: 12px;
    }
  }
`;

export const AddPluginButtonAlternate = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 0.3rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 85%;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  background-color: #313131;
  cursor: alias;

  ${Paragraph} {
    color: white;
  }

  @media (max-width: 1024px) {
    padding: 0.2rem;
    ${Paragraph} {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    padding: 0.1rem;
    ${Paragraph} {
      font-size: 9px;
    }
  }

  @media (max-width: 480px) {
    padding: 0.05rem;
    ${Paragraph} {
      font-size: 9px;
    }
  }
`;

export const PluginContainer = styled(Link)<{
  enabled: boolean;
  hasBorder?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  transition: ${({ theme }) => theme.transition};
  padding: 1rem 0.5rem;
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  border-bottom: ${(props) =>
    props.hasBorder ? '1px solid rgba(178, 165, 165, 0.18)' : 'none'};
  border-radius: 0;

  &:hover {
    opacity: 0.5;
  }
`;

export const OnlineLamp = styled.div`
  padding: 0.3rem;
  border-radius: 50%;
  background-color: #8bffcf;
`;

export const OfflineLamp = styled(OnlineLamp)`
  background-color: ${({ theme }) => theme.text};
`;

export const FlexColumnRightLayout = styled(FlexColumn)`
  height: 100%;
`;

export const ColumnRightTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.detailsText};
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2; /* Adjust line height for better readability */
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
`;

export const FlexRowProvider = styled(FlexRow)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
`;

export const ParagraphWithIcon = styled(Paragraph)`
  display: flex;
  align-items: center;
  font-size: 16px;

  ${VectorImage} {
    margin-left: 0.5rem;
  }

  @media (max-width: 1439px) {
    font-size: 14px;
  }
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
`;
export const FlexColumnProviderContainer = styled(FlexColumn)`
  position: relative;
  min-width: 0px;
  height: 40vh;
  display: flex;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.sideMenuAccent};
  border-radius: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid ${(props) => `${props.theme.detailsText}30`};
  flex-grow: 1; /* Make this container expand to fill available space */
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit-based browsers */
  }
`;

export const PluginContainerRightbar = styled(PluginContainer)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(178, 165, 165, 0.18);
  }
`;

export const NonClickablePluginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  cursor: default;
  border-bottom: 1px solid rgba(178, 165, 165, 0.18);
  border-radius: 0;
  opacity: 1;

  ${Paragraph}, ${Text} {
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    @media (min-width: 1800px) {
      font-size: 1.3rem !important;
    }
  }
`;

export const ParagraphBold20 = styled(Paragraph)`
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 1439px) {
    font-size: 16px;
  }
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
`;

export const PluginContainerButton = styled(HiddenButton)<{ enabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ${({ theme }) => theme.transition};
  padding: 1rem 0.5rem;
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  border-bottom: 1px solid rgba(178, 165, 165, 0.18);
  border-radius: 0;
  width: 100%;

  &:hover {
    opacity: 0.5;
  }

  ${Paragraph}, ${Text} {
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;

export const ResponsiveParagraph = styled(Paragraph)`
  font-size: 1rem;
  @media (max-width: 1440px) {
    font-size: 0.775rem !important;
  }
  @media (max-width: 1024px) {
    font-size: 0.75rem !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResponsiveText = styled(Text)`
  text-align: center;
  font-size: 0.875rem;

  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  @media (max-width: 1440px) {
    font-size: 0.75rem !important;
  }
  @media (max-width: 1440px) {
    font-size: 0.75rem !important;
  }
  @media (max-width: 1024px) {
    font-size: 0.625rem !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProviderTitle = styled(Paragraph)`
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
`;

export const ResponsiveImage = styled(Image)`
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const ThinLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.detailsText};
  opacity: 0.2;
`;
