import React from 'react';
import styled from 'styled-components';
import { HeadTitle, Paragraph } from '../../../App/Styles/Typography.styled';
import HelmetView from "../../../Components/Helmet/HelmetView";
import { VectorImage } from '../../../App/Styles/Image.styled';
import closeSvg from "../../../Assets/close.svg";

interface LoginCardProps {
    email: string | null;
    setEmail: (value: string | null) => void;
    password: string | null;
    setPassword: (value: string | null) => void;
    loading: boolean;
    errorMessage: string;
    isLoggedIn: string;
    makeLogin: () => void;
    setError: (value: string) => void;
    setIsLoginView: (value: boolean) => void; // Add this prop
}

interface InputContainerProps {
    title: string;
    inputType: string;
    onTextChange: (value: string | null) => void;
}

const InputContainer: React.FC<InputContainerProps> = ({ title, inputType, onTextChange }) => (
    <div>
        <InputLabel>{title}</InputLabel>
        <StyledInput type={inputType} onChange={(e) => onTextChange(e.target.value)} />
    </div>
);

const LoginCard: React.FC<LoginCardProps> = ({ email, setEmail, password, setPassword, loading, errorMessage, isLoggedIn, makeLogin, setError, setIsLoginView }) => {
    return (
        <CardContainer>
            <HelmetView title="Log In - Tarmac-AI" />
            <CardContent>
                <StyledHeadTitle bold={true}>Login</StyledHeadTitle>
                <StyledParagraph>Welcome back. Please enter your details</StyledParagraph>
                {errorMessage && (
                    <ErrorMessageContainer>
                        <HiddenButton onClick={() => setError("")}>
                            <VectorImage src={closeSvg} height="100%"/>
                        </HiddenButton>
                        <div>
                            <Paragraph>{errorMessage}</Paragraph>
                            <Footnote>{isLoggedIn}</Footnote>
                        </div>
                    </ErrorMessageContainer>
                )}
                <FormContainer>
                    <InputContainer title="Email" inputType="email" onTextChange={setEmail} />
                    <InputContainer title="Password" inputType="password" onTextChange={setPassword} />
                    <CheckboxContainer>
                        <div>
                            <Checkbox type="checkbox" id="remember" />
                            <CheckboxLabel htmlFor="remember">Remember password</CheckboxLabel>
                        </div>
                        <ForgotPassword href="#">Forgot password</ForgotPassword>
                    </CheckboxContainer>
                    <SubmitButton isLoading={loading} onClick={makeLogin}>
                        <ButtonText>Sign in</ButtonText>
                    </SubmitButton>
                    <SignUpContainer>
                        <SignUpText>Don’t have an account? <SignUpLink href="#" onClick={() => setIsLoginView(false)}>Sign up</SignUpLink></SignUpText>
                    </SignUpContainer>
                </FormContainer>
            </CardContent>
        </CardContainer>
    );
};

export default LoginCard;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    max-width: 600px;
    width: 100%;
    border-bottom: 1px solid rgba(199, 199, 199, 0.3); 
    border-right: 1px solid rgba(199, 199, 199, 0.3);
    border-top: none;
    background-color: rgba(0, 0, 0, 0.3); 

`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 1.5rem;
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1.5rem;

    width: 100%;
`;

const SubmitButton = styled.button<{ isLoading: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    background-color: #AD8449;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
    white-space: nowrap;
    font-size: 1em;

    &:hover {
        opacity: 0.8;
    }
`;

const HiddenButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
`;

const ButtonText = styled(Paragraph)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    color:#000000;
    font-size: 1.5em; 
    font-weight: bold;
`;

const Footnote = styled.p`
    font-size: 1em;
`;

const StyledHeadTitle = styled(HeadTitle)`
    margin-bottom: 1.5rem;
    font-size: 28px;
    align-self: flex-start;
    color: #f4f4f4;
    font-weight: 700;
`;

const StyledParagraph = styled(Paragraph)`
    font-size: 20px;
    align-self: flex-start;
    color:#f4f4f4; 
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Checkbox = styled.input`
    margin-right: .5rem;
    accent-color:#AD8449;
`;

const CheckboxLabel = styled.label`
    font-size: 1em;
    color: #C7C7C7;
`;

const ForgotPassword = styled.a`
    font-size: 1em;
    text-decoration: none;
    color: #AD8449;
`;

const SignUpContainer = styled.div`
    margin-top: 1.5rem;
    text-align: center;
`;

const SignUpText = styled(Paragraph)`
    font-size: 1em;
    color: #C7C7C7;
`;

const SignUpLink = styled.a`
    text-decoration: none;
    color: #AD8449;
    font-weight: 900;
`;

const InputLabel = styled.label`
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #C7C7C7; 
`;

const StyledInput = styled.input`
    font-size: 1.2em; /* Make the input text larger */
    font-family: inherit;
    padding: 0.5em 0.5em;
    margin-top: 1rem;
    width: 100%;
    background-color: transparent;
    color: #C7C7C7;
    border: 1px solid rgba(199, 199, 199, 0.6); /* Updated to use RGBA */
    border-radius: 10px;
    height: 45px;
    outline: none; /* Remove default outline */
    
    &:focus {
        border-color: #C7C7C7;
        box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
    }
`;
