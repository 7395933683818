import useAxios from "../useAxios/useAxios";
import { REGISTER_ENDPOINT, VERIFY_EMAIL_ENDPOINT } from "../../App/Axios/endpoints";

interface RegisterParams {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  dotNumber: string,
  company?: string | null,
  phone?: string | null,
  mcNumber?: string | null,
}

const useRegisterApi = () => {

  const axios = useAxios();

  const registerUser = async ({ firstName, lastName, email, password, dotNumber, company, phone, mcNumber }: RegisterParams): Promise<string> => {
    try {
      const form = new FormData();
      form.append("email", email);
      form.append("password", password);
      form.append("first_name", firstName);
      form.append("last_name", lastName);
      form.append("dot_number", dotNumber);
      form.append("mc_number", mcNumber || 'N/A');
      form.append("company_name", company || 'N/A');
      form.append("phone_number", phone || 'N/A');

      const res = await axios.post(REGISTER_ENDPOINT, form);
      if (res.status === 200 || res.status === 201) {
        return "";
      }
      return res.data.message;
    } catch (err: any) {
      console.log(err);
      return err!.response.data.message;
    }
  }

  const verifyEmail = async (token: string) => {

    try {
      const res = await axios.post(VERIFY_EMAIL_ENDPOINT + "?token=" + token);
      if (res.status === 200 || res.status === 201) {
        return true
      }
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  return { registerUser, verifyEmail };
}

export default useRegisterApi;
