import { Route, Routes } from "react-router-dom";
import MainOutlet from "../Pages/Main/MainOutlet";
import DefaultOutlet from '../Pages/DefaultOutlet';
import PrivateRoute from './PrivateRoute';
import LoginRedirectPage from "../Pages/Main/LoginRedirect/LoginRedirectPage";
import ChatPage from "../Pages/Main/Chat/ChatPage";
import LoginPage from "../Pages/Auth/Login/LoginPage";
import ContactPage from "../Pages/Contact/ContactPage";
import ErrorPage from "../Pages/Error/ErrorPage";
import EmailVerifyPage from "../Pages/EmailVerify/EmailVerify";
import HelpPage from "../Pages/Help/HelpPage";
import LoginRedirectRatingsPage from "../Pages/Main/LoginRedirect/LoginRedirectRatingsPage";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<MainOutlet/>}>
                <Route index element={<PrivateRoute component={ChatPage}/>}/>
            </Route>
            <Route path="/" element={<DefaultOutlet/>}>
                <Route path="copilot/oauth/callback/123loadboard" element={<LoginRedirectPage/>}/>
                <Route path="auth" element={<LoginPage/>}/>
                <Route path="contact" element={<ContactPage/>}/>
                <Route path="email-verification/:token" element={<EmailVerifyPage/>}/>
                <Route path="copilot/oauth/callback/truckstop" element={<LoginRedirectRatingsPage />} />
                <Route path="error" element={<ErrorPage/>}/>
                <Route path="help" element={<HelpPage/>}/>
            </Route>
            <Route path="*" element={<ErrorPage notfound/>}/>
        </Routes>
    );
};

export default Router;
