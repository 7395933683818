import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { VectorImage as OriginalVectorImage } from '../../App/Styles/Image.styled';
import {
  HeadTitle,
  Paragraph as OriginalParagraph,
} from '../../App/Styles/Typography.styled';
import { HiddenButton } from '../../App/Styles/Button.styled';
import profileSvg from '../../Assets/profile.svg';
import logoSvg from '../../Assets/logo.svg';
import closeSvg from '../../Assets/close.svg';
import { AuthenticationContext } from '../../Context/Auth/AuthContext';

const NavigationLoginLayout = () => {
  const authContext = useContext(AuthenticationContext);
  const [popOverEnabled, setPopOverEnabled] = useState<boolean>(false);

  const handleProfileClick = () => {
    setPopOverEnabled((previous) => !previous);
  };

  return (
    <Container>
      <LogoContainer to="/">
        <VectorImage src={logoSvg} white />
        <HeadTitleNavigation bold={true}>TarmacAI</HeadTitleNavigation>
      </LogoContainer>
      <LinkContainer
        href="https://tarmac-ai.com"
        gridColumn={2}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph>COMPANY</Paragraph>
      </LinkContainer>
      {/* <LinkContainer */}
      {/*     href="https://docs.google.com/forms/d/e/1FAIpQLSfK1O1lH9EDGrCZlCkyMaJ4CmzZBnF6onIcAhSEyQvr80IGLg/viewform" */}
      {/*     gridColumn={3} target="_blank" rel="noopener noreferrer" */}
      {/* > */}
      <LinkContainer href="/contact" gridColumn={3}>
        <Paragraph>CONTACT</Paragraph>
      </LinkContainer>
      <LinkContainer
        href="/help"
        gridColumn={4}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph>HELP</Paragraph>
      </LinkContainer>
      <ProfileContainer>
        {authContext?.tokens && (
          <HiddenButton padding="1rem" onClick={authContext.logoutListener}>
            <Paragraph>Logout</Paragraph>
          </HiddenButton>
        )}
        <HiddenButton onClick={handleProfileClick}>
          <VectorImage src={profileSvg} height="30px" width="30px" white />
        </HiddenButton>
        <ProfileDropdown tabIndex={0} enabled={popOverEnabled}>
          <ProfileDropdownBanner>
            <HiddenButton onClick={() => setPopOverEnabled(false)}>
              <VectorImage src={closeSvg} />
            </HiddenButton>
          </ProfileDropdownBanner>
          <DropdownItem href="/auth">
            <Paragraph>Login</Paragraph>
          </DropdownItem>
        </ProfileDropdown>
      </ProfileContainer>
    </Container>
  );
};

export default NavigationLoginLayout;

const VectorImage = styled(OriginalVectorImage)<{ white?: boolean }>`
  ${({ white }) =>
    white &&
    `
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(106%) contrast(106%);
    `}
`;

const Paragraph = styled(OriginalParagraph)`
  font-size: 24px;
  font-weight: 500;
  color: #c7c7c7;
`;

const HeadTitleNavigation = styled(HeadTitle)`
  color: #ad8449;
`;

const Container = styled.div`
  grid-area: nav;
  margin-left: 1rem;
  display: grid;
  gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  height: 4rem;
  background-color: transparent;
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  grid-column: 1;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;

const LinkContainer = styled.a<{ gridColumn: number | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: ${(props) => (props?.gridColumn ? props.gridColumn : 1)};

  &:hover {
    opacity: 0.7;
  }
`;

const ProfileDropdown = styled.div<{ enabled: boolean }>`
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #f4f4f4;
  background-color: #000000;
  border: 1px solid #191919;
  border-radius: 10px;
  position: absolute;
  align-items: end;
  padding: 1rem;
  transform: translate(2rem, 5.5rem);
  opacity: 0;
  float: end;
  ${(props) =>
    props.enabled ? 'opacity: 1; transform: translate(1rem, 5.5rem);' : ''}
`;

const ProfileDropdownBanner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid rgba(178, 165, 165, 0);

  &:hover {
    opacity: 0.7;
    border-color: rgba(178, 165, 165, 0.2);
  }
`;

const ProfileContainer = styled.div`
  color: #f4f4f4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  margin-right: 2rem;
  gap: 1.5rem;
  grid-column: 6;
`;
