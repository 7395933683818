import React from 'react';
import styled from 'styled-components';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import ThemeToggle from '../../Components/Toogle/ThemeToggle';

const BottombarContent = () => {
  return (
    <Container>
      <IconsContainer>
        <StyledLinkedInIcon />
        <StyledInstagramIcon />
        <FeedbackText>Give Feedback</FeedbackText>
      </IconsContainer>
      <ToggleContainer>
        <ThemeToggle />
      </ToggleContainer>
    </Container>
  );
};

export default BottombarContent;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: transparent;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FeedbackText = styled.div`
  font-size: 18px;
  font-weight: lighter;
  color: ${({ theme }) => theme.alternateText};
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  font-size: 28px !important;
  color: ${({ theme }) => theme.alternateText};
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  font-size: 28px !important;
  color: ${({ theme }) => theme.alternateText};
`;
