import { FC } from "react";
import styled from "styled-components";
import NavigationLoginLayout from "../../Layouts/Navigation/NavigationLogInLayout";
import BottombarLayout from "../../Layouts/Bottombar/BottombarLayout";
import { HeadTitle, Title, Paragraph, Text } from "../../App/Styles/Typography.styled"
import { nameToRgba } from "../../Utils/colorUtils";

const HelpPage: FC = () => {
    return (
        <Container>
            <NavigationLoginLayout/>
                <ContentContainer>
                <PageTitle bold>Guide For First Time Users</PageTitle>

                <StyledTitle bold>Useful Tips:</StyledTitle>
                <List>
                    <ListItem>
                        <Paragraph>Start with asking loads from any <Highlight color="green">state or city</Highlight> by mentioning a <Highlight color="dodgerblue">truck type</Highlight> such as “loads from Austin for van trucks” or from “loads from Texas for flatbed”</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>If you don’t mention the <Highlight color="dodgerblue">truck type</Highlight>, the system will ask you to choose the truck type by providing the options</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph> You can ask follow up questions by not mentioning the <Highlight color="green">state or city</Highlight> and the system will provide the results from the same state or city.</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>But when you want to find loads from different states or city, just ask loads from a different state or city</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>The system will only provide answers to <Highlight color="red">price</Highlight>, <Highlight color="deeppink">rate per mile</Highlight>, <Highlight color="darkorchid">load</Highlight>, <Highlight color="dodgerblue">pick up</Highlight>, <Highlight color="orange">origin</Highlight>, <Highlight color="yellow">post age</Highlight>, <Highlight color="red">company</Highlight>, <Highlight color="green">equipment</Highlight>, <Highlight color="dodgerblue">mileage</Highlight> related questions or double, triple or more combinations of the factors. See the below playbook for some sample prompts</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>If you don't specify the number of loads, the system will give 5 loads by default.</Paragraph>
                    </ListItem>
                </List>

                <StyledTitle bold>Sample Prompts: <Italic>(Subject to Availability of Data)</Italic></StyledTitle> 
                <Italic>Mention the <Highlight color="dodgerblue">truck type</Highlight> when you initiate a search from any city or state. Otherwise the system will ask you to choose the truck type.</Italic>
                <List>
                    <Title>City/Location</Title>
                    <ListItem>
                        <Paragraph>Give me loads from Austin for Van trucks</Paragraph>
                    </ListItem>
                    <Title>Load Amount</Title>
                    <ListItem>
                        <Paragraph>Tell me 23 loads from Atlanta for Step Deck trucks</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Give me 15 loads from Charlotte for flatbed trucks</Paragraph>
                    </ListItem>
                    <Title>Truck type</Title>
                    <ListItem>
                        <Paragraph> Loads with flatbed trucks from Austin </Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph> Loads from Phoenix for Van trucks </Paragraph>
                    </ListItem>
                    <Title>Loads from Kansas City with higher than $1300 price</Title>
                    <ListItem>
                        <Paragraph>Give 7 Loads from Houston with RPM (or rate per mile) higher than 1.7</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Chicago lower than $1300 price</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Dallas  with RPM (or rate per mile) less than 1.7</Paragraph>
                    </ListItem>
                    <Title>Mileage</Title>
                    <ListItem>
                        <Paragraph>List of loads from Fort Worth with less than 150 miles</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Jacksonville with transportation distance higher than 250 miles</Paragraph>
                    </ListItem>
                    <Title>Pick Up Dates</Title>
                    <ListItem>
                        <Paragraph>Loads from San Antonio with pick up date after 15th of May</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Albany with pick up date before 12th of June</Paragraph>
                    </ListItem>
                </List>

                <StyledTitle>
                    <Italic>
                        <Highlight color="green">
                            Combine Two Factors:
                        </Highlight>
                    </Italic>
                </StyledTitle>
                <List>
                    <Title>Origin</Title>
                    <ListItem>
                        <Paragraph>Loads from Baltimore posted in last 10 hours</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Buffalo posted in last 12 hours minutes </Paragraph>
                    </ListItem>
                    <Title>Age + Destination</Title>
                    <ListItem>
                        <Paragraph>Loads to Dewitt city from Buffalo posted in last 24 hour</Paragraph>
                    </ListItem>
                    <Title>Equipment type</Title>
                    <ListItem>
                        <Paragraph>List of loads from Houston posted in last 12 hours for Flatbed</Paragraph>
                    </ListItem>
                    <Title>Price</Title>
                    <ListItem>
                        <Paragraph>List of loads from Charlotte posted in last 10 hours higher than $1000</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>List of loads from Baltimore posted in last 22 hours lower than $1.5 rate per mile</Paragraph>
                    </ListItem>
                    <Title>Age + Mileage</Title>
                    <ListItem>
                        <Paragraph>List of loads from Seattle posted in last 21 hours with lower than 1100 miles.</Paragraph>
                    </ListItem>
                    <Title>Age + Pickup</Title>
                    <ListItem>
                        <Paragraph>Get me loads from Memphis posted in last 30 minutes with a pickup date after 3rd of June</Paragraph>
                    </ListItem>
                    <Title>Origin + Equipment type:</Title>
                    <ListItem>
                        <Paragraph>Loads from Brooklyn for flatbed trucks</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Give me Loads from Austin for Van trucks</Paragraph>
                    </ListItem>
                    <Title>Origin + Price</Title>
                    <ListItem>
                        <Paragraph>Give me loads priced higher than $350 from Albany, NY</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads priced lower than $12.5 rate per mile from Amarillo</Paragraph>
                    </ListItem>
                    <Title>Origin+ Mileage</Title>
                    <ListItem>
                        <Paragraph>Loads from Kansas city with less than 750 miles</Paragraph>
                    </ListItem>
                    <Title>Origin+ Pickup date</Title>
                    <ListItem>
                        <Paragraph>Loads from Kansas City, MO with pickup date after June3rd</Paragraph>
                    </ListItem>
                    <Title>Equipment + Price:</Title>
                    <ListItem>
                        <Paragraph>Flatbed truck loads from Austin higher than $1200</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Reefer truck loads from Nashville higher than $1.2 rate per mile</Paragraph>
                    </ListItem>
                    <Title>Equipment + Pickup:</Title>
                    <ListItem>
                        <Paragraph>Van truck loads from Atlanta with pickup date after 3rd of June</Paragraph>
                    </ListItem>
                    <Title>Price + Mileage:</Title>
                    <ListItem>
                        <Paragraph>Loads from Memphis  higher than $500 but less than 750 miles</Paragraph>
                    </ListItem>
                    <Title>Price + Pickup:</Title>
                    <ListItem>
                        <Paragraph>Loads from Boston higher than $500 in price with pickup date after June 5</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Give 12 loads from Phoenix higher than $350 with pickup date after June 5t</Paragraph>
                    </ListItem>
                    <Title>Mileage + Pickup:</Title>
                    <ListItem>
                        <Paragraph>List of loads from Indianapolis less than a transportation distance of 1800 miles with pickup date after 20th of June</Paragraph>
                    </ListItem>
                </List>

                <StyledTitle>
                    <Italic>
                        <Highlight color="dodgerblue">
                             More Complicated Combinations
                        </Highlight>
                    </Italic>
                </StyledTitle>
                <List>
                    <ListItem>
                        <Paragraph>Show me 9 loads from California posted in last 12 hours, higher than 500$, with pick up dates after June 30, rank them with highest rate per mile</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Show me 12 loads from Atlanta posted in last 23 hours with transportation distance higher than 300 miles but lower than $1500 in price</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph>Loads from Houston posted in the last 21 hours with cargo weight higher than 15000 lbs, pickup date after 25th June, rank them with highest rate per mile.</Paragraph>
                    </ListItem>
                </List>
                </ContentContainer>
            <BottombarLayout/>
        </Container>
    )
}

export default HelpPage;

const Container = styled.div`
    height: 100vh;
`;

const List = styled.ul`
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 2rem;
    color: ${props => props.theme.text};
`
const ListItem = styled.li`
`
const Italic = styled(Text)`
    font-style: italic;
    color: ${props =>  props.theme.text};
`
const ContentContainer = styled.div`
    width: 75%;
    margin: 0 auto;
`;

const PageTitle = styled(HeadTitle)`
    text-align: center;
    margin-top: 2rem;
`;

const StyledTitle = styled(Title)`
    margin-top: 2rem
`;

const Highlight = styled.span<{ color: string }>`
  background-color: ${({ color }) => nameToRgba(color)};
  padding: 0.2em;
  border-radius: 0.3em;
`;

