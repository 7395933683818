import React from 'react';
import styled from 'styled-components';
import { HeadTitle, Paragraph } from '../../../App/Styles/Typography.styled';
import HelmetView from "../../../Components/Helmet/HelmetView";
import { VectorImage } from '../../../App/Styles/Image.styled';
import closeSvg from "../../../Assets/close.svg";
import InputContainer from '../../../Components/InputContainer/InputContainer';
import { Popup } from '../../../Components/Popup/Popup';

interface SignUpCardProps {
    firstName: string | null;
    setFirstName: (value: string | null) => void;
    lastName: string | null;
    setLastName: (value: string | null) => void;
    email: string | null;
    setEmail: (value: string | null) => void;
    password: string | null;
    setPassword: (value: string | null) => void;
    phone: string | null;
    setPhone: (value: string | null) => void;
    mcNumber: string | null;
    setMcNumber: (value: string | null) => void;
    company: string | null;
    setCompany: (value: string | null) => void;
    dotNumber: string | null;
    setDotNumber: (value: string | null) => void;
    loading: boolean;
    errorMessage: string;
    setSuccess: (value: string) => void;
    successMessage: string;
    setError: (value: string) => void;
    handleRegister: () => void;
    setIsLoginView: () => void;
}

const SignUpCard: React.FC<SignUpCardProps> = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    phone,
    setPhone,
    mcNumber,
    setMcNumber,
    company,
    setCompany,
    dotNumber,
    setDotNumber,
    loading,
    successMessage,
    setSuccess,
    errorMessage,
    setError,
    handleRegister,
    setIsLoginView
}) => {
    return (
        <CardContainer>
            <HelmetView title="Sign Up - Tarmac-AI" />
            <CardContent>
                <StyledHeadTitle bold={true}>Sign Up</StyledHeadTitle>
                <StyledParagraph>Enter your details to register with us.</StyledParagraph>
                {successMessage && <Popup title="Success!" subtitle={successMessage} handleExit={() => setSuccess("")}/>}
                <FormContainer>
                    <DoubleInputContainer>
                        <InputContainer title="First name" inputType="text" value={firstName} onTextChange={setFirstName} required/>
                        <InputContainer title="Last name" inputType="text" value={lastName} onTextChange={setLastName} required/>
                    </DoubleInputContainer>
                    <InputContainer title="Email" inputType="email" value={email} onTextChange={setEmail} required/>
                    <InputContainer title="Password" inputType="password" value={password} onTextChange={setPassword} required/>
                    <StyledSubtext>*Minimum password length is 8 characters</StyledSubtext>
                    <InputContainer title="DOT Number" inputType="text" value={dotNumber} onTextChange={setDotNumber} required/>
                    <DoubleInputContainer>
                        <InputContainer title="Phone" inputType="tel" value={phone} onTextChange={setPhone}/>
                        <InputContainer title="MC Number" inputType="tel" value={mcNumber} onTextChange={setMcNumber}/>
                    </DoubleInputContainer>
                    <InputContainer title="Company" inputType="text" value={company} onTextChange={setCompany}/>
                    {errorMessage && (
                        <ErrorMessageContainer>
                            <HiddenButton onClick={() => setError("")}>
                                <VectorImage src={closeSvg} height="100%" />
                            </HiddenButton>
                            <div>
                                <Paragraph>{errorMessage}</Paragraph>
                            </div>
                        </ErrorMessageContainer>
                    )}
                    <SubmitButton isLoading={loading} onClick={handleRegister}>
                        <ButtonText>Sign Up</ButtonText>
                    </SubmitButton>
                    <TermsText>
                        By creating an account you agree to the <TermsLink href="#">Terms of Service</TermsLink>
                    </TermsText>
                    <SignUpTextContainer>
                        <SignUpText>
                            Already have an account? <SignUpLink href="#" onClick={setIsLoginView}>Log in</SignUpLink>
                        </SignUpText>
                    </SignUpTextContainer>
                </FormContainer>
            </CardContent>
        </CardContainer>
    );
};

export default SignUpCard;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    max-width: 600px;
    width: 100%;
    border-bottom: 1px solid rgba(199, 199, 199, 0.3); 
    border-right: 1px solid rgba(199, 199, 199, 0.3);
    border-top: none;
    background-color: rgba(0, 0, 0, 0.3); 
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.div`
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;

const DoubleInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem; 
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
`;

const SubmitButton = styled.button<{ isLoading: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    background-color: #AD8449;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
    white-space: nowrap;
    font-size: 1em;

    &:hover {
        opacity: 0.8;
    }
`;
const HiddenButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
`;

const ButtonText = styled(Paragraph)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    color: #000000;
    font-size: 1.5em; 
    font-weight: bold;
`;

const StyledHeadTitle = styled(HeadTitle)`
    margin-bottom: 1rem;
    align-self: flex-start;
    color: white;
    font-weight: bolder;
`;

const StyledParagraph = styled(Paragraph)`
    font-size: 20px;
    align-self: flex-start;
    color: #f4f4f4;
`;

const StyledSubtext = styled(StyledParagraph)`
    font-size: 1rem;
    color: #AFAFAF;
`;

const TermsText = styled(Paragraph)`
    text-align: center;
    font-size: 0.9em;
    color: #AFAFAF;
    font-weight: lighter;
`;

const TermsLink = styled.a`
    color: #AFAFAF;
    font-weight: 900;
`;

const SignUpTextContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const SignUpText = styled(Paragraph)`
    font-size: 1em;
    color:#C7C7C7;

`;

const SignUpLink = styled.a`
    color: #AD8449;
    font-weight: 900;
`;

