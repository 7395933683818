import styled from 'styled-components';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { sizes } from '../../Utils/screenUtil';
import { Paragraph } from '../../App/Styles/Typography.styled';

export const Container = styled.div<{ $isLeftbarOpen: boolean }>`
  grid-area: left;
  display: flex;
  z-index: 1;
  flex-direction: column;
  margin-top: 2rem;
  height: 100%;
  padding: 0rem 0rem 1rem 1.5rem;
  width: ${({ $isLeftbarOpen }) =>
    $isLeftbarOpen ? '280px' : '0px'}; /* Adjust width on open/close */
  transition: width 0.25s ease; /* Smooth transition for width */
  @media only screen and (max-width: ${sizes.laptopMid}) {
    width: ${({ $isLeftbarOpen }) =>
      $isLeftbarOpen ? '200px' : '0px'}; /* Adjust width on open/close */
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    height: 95%;
    padding-left: 0;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    height: 85%;
    margin-top: 5rem;
  }
`;

export const CenterContainer = styled.div<{
  $isLeftbarOpen: boolean;
  $isLeftbarTransitioning: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 20px;
  min-height: 90%;
  background-color: ${({ theme }) => theme.sideMenu};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border-top: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-left: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-right: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-bottom: none;
    border: ${(
      { $isLeftbarOpen, $isLeftbarTransitioning } // This fixes bug where borders still exist on sidebar closed
    ) => (!$isLeftbarOpen && !$isLeftbarTransitioning ? 'none' : '')};
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    pointer-events: none;
    @media only screen and (max-width: ${sizes.tablet}) {
      border-radius: 0px 10px 10px 0;
    }
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens GREATER than 2240px pixels */
    width: 450px;
    transform: translateX(10px);
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens SMALLER than tablets */
    padding: 0;

    border-radius: 0px 10px 10px 0;
  }
`;
export const AddonContainer = styled(Link)<{
  $enabled: boolean;
  $isLeftbarOpen: boolean;
  $isLeftbarTransitioning?: boolean;
}>`
  display: flex;

  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 10px;
  opacity: ${({ $isLeftbarOpen, $isLeftbarTransitioning }) =>
    $isLeftbarOpen && !$isLeftbarTransitioning ? '1' : '0'}; /* Fade effect */
  visibility: ${({ $isLeftbarOpen, $isLeftbarTransitioning }) =>
    $isLeftbarOpen && !$isLeftbarTransitioning
      ? 'visible'
      : 'hidden'}; /* Hide element when not visible */
  transition: opacity 0.25s ease, visibility 0.25s ease; /* Smooth transition for fade effect */
  padding: 1rem 1rem;
  cursor: ${({ $enabled }) => ($enabled ? 'pointer' : 'default')};
  &:hover {
    opacity: ${({ $enabled }) => ($enabled ? 0.5 : 1)};
    cursor: not-allowed;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    max-width: 80%;
    margin-left: 20px;
  }
`;

export const SettingsContainer = styled.div<{
  $isLeftbarOpen: boolean;
  $isLeftbarTransitioning?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 8px;
  padding: 1rem 1rem;
  opacity: ${({ $isLeftbarOpen, $isLeftbarTransitioning }) =>
    $isLeftbarOpen && !$isLeftbarTransitioning ? '1' : '0'}; /* Fade effect */
  visibility: ${({ $isLeftbarOpen, $isLeftbarTransitioning }) =>
    $isLeftbarOpen && !$isLeftbarTransitioning
      ? 'visible'
      : 'hidden'}; /* Hide element when not visible */
  transition: opacity 0.25s ease, visibility 0.25s ease; /* Smooth transition for fade effect */
  @media only screen and (max-width: ${sizes.tablet}) {
    max-width: 80%;
    margin-left: 20px;
  }
`;

export const OnlineLamp = styled.div`
  padding: 0.3rem;
  border-radius: 50%;
  background-color: #33a733;
`;

export const OfflineLamp = styled(OnlineLamp)`
  background-color: #ac2c2c;
`;

export const LeftBarParagraph = styled(Paragraph)`
  color: ${(props) => props.theme.detailsText};
  font-size: 1rem;
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-size: 1.6rem !important;
    font-weight: bold;
  }
`;

// Temporary Link solution
export const AbsoluteReactLink = styled(Link)<{ bottom: string }>`
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  display: none;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than tablet widths */
    display: inline;
  }
`;

export const ComingSoon = styled(Paragraph)`
  font-size: 16px;
  color: grey;
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-size: 1.5rem !important;
  }
`;

export const ArrowIconContainer = styled.div`
  position: absolute;
  display: flex; /* Enables Flexbox */
  align-items: center; /* Centers icon vertically */
  justify-content: center; /* Centers icon horizontally */
  right: 15px;
  bottom: 50%;
  width: 40px;
  height: 40px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 1320px) {
    /* Styles for screens less than 1320px pixels */
    transform: translate(8px, 0);
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    display: none;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    display: none;
  }
`;

export const LeftArrow = styled(KeyboardDoubleArrowLeftIcon)`
  color: ${({ theme }) => theme.arrowIcon};
  :hover {
    color: ${({ theme }) => theme.arrowIconHovered};
  }
`;

export const RightArrow = styled(KeyboardDoubleArrowRightIcon)`
  color: ${({ theme }) => theme.arrowIcon};
  :hover {
    color: ${({ theme }) => theme.arrowIconHovered};
  }
`;
