import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticationContext } from '../Context/Auth/AuthContext';

type Props = {
  component: React.ComponentType;
};

const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const authContext = useContext(AuthenticationContext);
  const tokens = authContext?.tokens;
  return tokens ? <RouteComponent /> : <Navigate to={'/auth'} />;
};

export default PrivateRoute;
