import styled from 'styled-components';
import { useUser } from '../../Context/User/useUser';

interface InputGroupProps {
  label: string;
  name: string;
  type: string;
  value: string;
  options?: string[]; // For the select dropdown, this is optional
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  placeholder?: string; // Optional for input placeholder
}

export const InputGroup: React.FC<InputGroupProps> = ({
  label,
  name,
  type,
  value,
  options,
  handleInputChange,
  placeholder,
}) => {
  const { fetchSettingsError } = useUser();

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      {type === 'select' ? (
        <Select
          name={name}
          id={name}
          value={value}
          onChange={handleInputChange}
          disabled={!!fetchSettingsError}
        >
          <option value="">Select {label}</option>
          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          disabled={!!fetchSettingsError}
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder || ''}
        />
      )}
    </FormGroup>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.text};
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;
