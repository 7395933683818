const BASE_URL = process.env.REACT_APP_SERVER_API_URL;

const TOKEN_URL = `${BASE_URL}/token`;
const USERS_URL = `${BASE_URL}/users`;
const LOADS_URL = `${BASE_URL}/loads`;
const CHAT_URL = `${BASE_URL}/chat`;


export const LOGIN_ENDPOINT = `${USERS_URL}/login`;
export const REFRESH_ENDPOINT = `${TOKEN_URL}/refresh`;


// LOADS
export const LB123_LOGIN_ENDPOINT = `${LOADS_URL}/lb123/login`;
export const LB123_ADD_TOKENS_ENDPOINT = `${LOADS_URL}/lb123/tokens`;
export const LB123_LOADS_ENDPOINT = `${LOADS_URL}/lb123/list`;
export const LB123_DETAILS_ENDPOINT = `${LOADS_URL}/lb123/details`;
export const TRUCKSTOP_DETAILS_ENDPOINT = `${LOADS_URL}/truckstop/details`;
export const FMCSA_DETAILS_ENDPOINT = `${LOADS_URL}/fmcsa/details`;

// CHAT
export const LOGIN_123LB_ENDPOINT = `${CHAT_URL}/123lb/login`;
export const LOGIN_TRUCKSTOP_ENDPOINT = `${CHAT_URL}/truckstop/login`;
export const CHECK_TRUCKSTOP_ENDPOINT = `${CHAT_URL}/truckstop/check`;
export const SEND_MESSAGE_ENDPOINT = `${CHAT_URL}/message`;
export const TRUCKSTOP_RATINGS_ENDPOINT= `${CHAT_URL}/truckstop/login/rate-insights`;


// USERS
export const SEND_EMAIL_ENDPOINT = `${USERS_URL}/send-email`;
export const REGISTER_ENDPOINT = `${USERS_URL}/register`;
export const VERIFY_EMAIL_ENDPOINT = `${USERS_URL}/email-verification`;

