import React, { createContext, useState, useEffect } from 'react';
import useChatApi from '../../Hooks/useApi/useChatApi';

export interface UserSettings {
  origin_state: string;
  origin_city: string;
  equipment_type: string;
  companies: string[];
}
interface UserContextType {
  settings: UserSettings;
  settingsIsLoading: boolean;
  updateSettings: (updatedSettings: UserSettings) => Promise<void>;
  fetchSettingsError: string | null;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

// Default values for UserSettings
const defaultSettings: UserSettings = {
  origin_state: '',
  origin_city: '',
  equipment_type: '',
  companies: [],
};

// Provider component
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<UserSettings>(defaultSettings);
  const [settingsIsLoading, setSettingsIsLoading] = useState<boolean>(false);
  const [fetchSettingsError, setFetchSettingsError] = useState<string | null>(
    null
  );
  const { fetchChatSettings, updateChatSettings } = useChatApi();

  // Fetch user settings on app load
  useEffect(() => {
    const initializeSettings = async () => {
      setFetchSettingsError(null);

      try {
        const fetchedSettings = await fetchChatSettings();
        if (fetchedSettings) {
          setSettings(fetchedSettings); // Update the state with fetched settings
        } else {
          setFetchSettingsError('Failed to fetch user settings.'); // Handle case where response is empty or invalid
        }
      } catch {
        setFetchSettingsError('Error fetching user settings.');
      }
    };
    initializeSettings(); // Initialize settings on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update settings function
  const updateSettings = async (updatedSettings: UserSettings) => {
    setSettingsIsLoading(true); // Start loading when updating settings
    try {
      await updateChatSettings(updatedSettings); // Make the patch request
      setSettings(updatedSettings); // Update the local state with the new settings
    } catch (error) {
      console.error('Error updating user settings:', error);
    } finally {
      setSettingsIsLoading(false); // Stop loading when the request is complete
    }
  };

  return (
    <UserContext.Provider
      value={{
        settings,
        settingsIsLoading,
        updateSettings,
        fetchSettingsError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
